import { NextPage } from "next";
import Link from "next/link";
import Image from "next/image";
import classes from "../styles/pages/404.module.scss";

const Custom404: NextPage = () => {
  return (
    <div className={classes["container"]}>
      <div className={classes["logo"]}>
        <Link href="/">
          <a>
            <Image
              src="/logo-1.svg"
              width={100}
              height={100}
              alt="Logo Bivouac"
            />
          </a>
        </Link>{" "}
      </div>
      <span className={classes["error-code"]}>
        404
        <br />
      </span>
      <h1 className={classes["title"]}>
        Cette page n&apos;existe pas <br /> 😵{" "}
      </h1>
      <Link href="/">
        <a>Retour à l&apos;accueil</a>
      </Link>
    </div>
  );
};
export default Custom404;
